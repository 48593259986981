// Generated by Framer (d7e8e3e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Hero } from "https://framerusercontent.com/modules/hKjtTuWGYB451ckw6eTN/rQJeCcsGr1fSnWO0FT2G/Hero.js";
const HeroFonts = getFonts(Hero);

const cycleOrder = ["GQEYGtCvO"];

const serializationHash = "framer-M78aq"

const variantClassNames = {GQEYGtCvO: "framer-v-1z6lgz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({color, height, iconColor, id, width, ...props}) => { return {...props, DxFECmhqH: color ?? props.DxFECmhqH ?? "var(--token-e8dc42fd-fdba-4ae3-b5ee-1d25f6ddbf17, rgb(254, 146, 37)) /* {\"name\":\"Secondary Orange\"} */", iJ_vf1x3s: iconColor ?? props.iJ_vf1x3s ?? "var(--token-1055a5dc-b1d3-41b9-8066-a3abfbbab218, rgb(255, 255, 255)) /* {\"name\":\"Icon\"} */"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;color?: string;iconColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DxFECmhqH, iJ_vf1x3s, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "GQEYGtCvO", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-M78aq", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1z6lgz", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"GQEYGtCvO"} ref={ref ?? ref1} style={{backgroundColor: DxFECmhqH, borderBottomLeftRadius: 1000, borderBottomRightRadius: 1000, borderTopLeftRadius: 1000, borderTopRightRadius: 1000, ...style}}><motion.div className={"framer-1grrz5-container"} data-framer-name={"Arrow Icon"} layoutDependency={layoutDependency} layoutId={"pGfaQ8xiy-container"} name={"Arrow Icon"}><Hero color={iJ_vf1x3s} height={"100%"} iconSearch={"Home"} iconSelection={"ArrowUp"} id={"pGfaQ8xiy"} layoutId={"pGfaQ8xiy"} mirrored={false} name={"Arrow Icon"} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-M78aq [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-M78aq .framer-186h2oi { display: block; }", ".framer-M78aq .framer-1z6lgz { height: 50px; position: relative; width: 50px; }", ".framer-M78aq .framer-1grrz5-container { flex: none; height: 28px; left: calc(50.00000000000002% - 28px / 2); position: absolute; top: calc(50.00000000000002% - 28px / 2); width: 28px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 50
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"DxFECmhqH":"color","iJ_vf1x3s":"iconColor"}
 * @framerImmutableVariables true
 */
const FramerHcFliHZYi: React.ComponentType<Props> = withCSS(Component, css, "framer-M78aq") as typeof Component;
export default FramerHcFliHZYi;

FramerHcFliHZYi.displayName = "Scroll-to-Top Button";

FramerHcFliHZYi.defaultProps = {height: 50, width: 50};

addPropertyControls(FramerHcFliHZYi, {DxFECmhqH: {defaultValue: "var(--token-e8dc42fd-fdba-4ae3-b5ee-1d25f6ddbf17, rgb(254, 146, 37)) /* {\"name\":\"Secondary Orange\"} */", title: "Color", type: ControlType.Color}, iJ_vf1x3s: {defaultValue: "var(--token-1055a5dc-b1d3-41b9-8066-a3abfbbab218, rgb(255, 255, 255)) /* {\"name\":\"Icon\"} */", title: "Icon Color", type: ControlType.Color}} as any)

addFonts(FramerHcFliHZYi, [...HeroFonts])